import Aos from "aos";
import { useEffect } from "react"

const Loader = () => {
    useEffect(() => {
        Aos.init();
    }, [])
    
    return(
        <section>
            <div id="preloader">
                    <div id="ctn-preloader" className="ctn-preloader">
                        <div className="animation-preloader">
                            <div className="icon"><img src="/images/logo/logo_04.png" className="m-auto d-block" width="38"/>
                            </div>
                            <div className="txt-loading mt-2">
                                <span data-text-preloader="E" className="letters-loading">
                                    E
                                </span>
                                <span data-text-preloader="N" className="letters-loading">
                                    N
                                </span>
                                <span data-text-preloader="I" className="letters-loading">
                                    I
                                </span>
                                <span data-text-preloader="G" className="letters-loading">
                                    G
                                </span>
                                <span data-text-preloader="M" className="letters-loading">
                                    M
                                </span>
                                <span data-text-preloader="A" className="letters-loading">
                                    A
                                </span>
                            </div>
                        </div>
                    </div>
            </div>
        </section>
    )
}

export default Loader
