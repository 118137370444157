import { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import http from '../../../http';
import { Navigate } from 'react-router-dom';
import { Alert, CircularProgress } from '@mui/material';
import useScript from '../../../hooks/useScript';

function Login() {

    const invisible_element = {display: "none"}
    
    const [valueEmail, setValueEmail] = useState('')
    const [valueSenha, setValueSenha] = useState('')
    const [usuarioLogado, setUsuarioLogado] = useState(false)
    
    useEffect(() => {
        AOS.init();
      }, []);

    function toggleSenhaVisivel() {
      const campoSenha = document.getElementById("senha") as HTMLInputElement
      const imagemVisao = document.getElementById("passVisonToggle") as HTMLInputElement
      
      if (campoSenha && imagemVisao) {
        if (campoSenha.type === "password") {
          campoSenha.type = "text";
          imagemVisao.classList.toggle("eye-slash")
        } else {
          campoSenha.type = "password";
          imagemVisao.classList.toggle("eye-slash")
        }
      }
    }

    const aoSubmeterLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const carregandoComponent = document.getElementById("carregandoComponent")
        if (carregandoComponent) {
            carregandoComponent.style.display = "flex"
        }

        http.get(`login`, {
            auth: {
                username: valueEmail,
                password: valueSenha
            }
        })
        .then(response => {
            sessionStorage.setItem('token', response.data.token)
            //sessionStorage.setItem('usuario', response.data.username)
            setUsuarioLogado(true)
        })
        .catch(erro => {
            setValueEmail("")
            setValueSenha("")
            const elemento = document.getElementById("mensagemErro")
            if (elemento) {
            elemento.style.display = "";
            }
            if (carregandoComponent) {
                carregandoComponent.style.display = "none"
            }
        })
    }

    if (usuarioLogado) {
        return <Navigate to="/admin" />
    } else {
        return (
            <>
            <div id="carregandoComponent" style={{display : "none", position: "absolute", top : "0", left : "0", width: "100%", height: "100%", zIndex: "2", backgroundColor: "rgba(0, 0, 0, 0.5)", alignItems: "center", justifyContent : "center"}}> <CircularProgress style={{width : "70px", height : "70px"}}/></div>
            <div className="main-page-wrapper">

                <div className="user-data-page clearfix d-md-flex">
                    <div className="illustration-wrapper d-none d-md-flex align-items-center">
                        <h3>Gostaria de alavancar <br/> os seus negócios? Consulte <a href="/">nosso site</a></h3>
                        <div className="illustration-holder">
                            <img src="images/assets/ils_22.svg" alt="Animação de um homem segurando uma lanterna" className="illustration m-auto"/>
                        </div>
                    </div>

                    <div className="form-wrapper">
                        <div className="d-flex justify-content-between align-items-center" data-aos="fadeup">
                            <div className="logo"><a href="/" className="d-block"><img src="images/logo/logo_01.png" alt="Logo da Enigma" width="131"/></a></div>
                            <a href="/" className="go-back-button tran3s" style={{textDecoration: "none"}}>Voltar</a>
                        </div>
                        <form action="#" className="user-data-form mt-60 lg-mt-40" onSubmit={aoSubmeterLogin}>
                            <h2>Olá, seja bem vindo <br/> de volta!</h2>
                            <Alert severity="error" id="mensagemErro" style={invisible_element}>Email ou senha inválidos!</Alert>
                            <p className="header-info pt-20 pb-20 lg-pt-10 lg-pb-10">Ainda não possui uma conta? <a href="/cadastro">Se inscreva</a></p>

                            <div className="row">
                                <div className="col-12">
                                    <div className="input-group-meta mb-25">
                                        <label>Email</label>
                                        <input type="email" placeholder="seuemail@email.com" onChange={event => setValueEmail(event.target.value)} value={valueEmail} required/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="input-group-meta mb-25">
                                        <label>Senha</label>
                                        <input type="password" placeholder="Sua senha" id="senha" className="pass_log_id" onChange={event => setValueSenha(event.target.value)} value={valueSenha} required/>
                                        <span className="placeholder_icon" onClick={toggleSenhaVisivel}><span className="passVicon" id="passVisonToggle"><img src="images/icon/icon_40.svg" alt="Olho que indica se a senha é visível. Inicia como não visível" /></span></span>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="btn-eight w-100 mt-50 mb-40 lg-mt-30 lg-mb-30">Login</button>
                                </div>
                                <div className="col-12">
                                    <p className="text-center copyright-text m0">Copyright @2022 Enigma - Web Development</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
		    </div>
            </>
        )
    }
}

export default Login
