import Aos from "aos"
import { useEffect, useState } from "react"
import http from "../../http"
import IBeneficios from "../../interfaces/IBeneficios"

interface BeneficiosProps {
    beneficios : IBeneficios[]
}

const Beneficios = ({beneficios} : BeneficiosProps) => {
    
    useEffect(() => {
         Aos.init();
    
    }, [])
 
    return (
        <div className="fancy-feature-eighteen position-relative pt-100 pb-225 lg-pt-130 md-pt-100 xl-pb-150 lg-pb-100" id="beneficios">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-lg-6 col-md-7 ms-auto">
                        <div className="block-style-two" data-aos="fade-left">
                            <div className="title-style-three">
                                <h2 className="main-title">{beneficios[0]?.value}</h2>
                            </div>
                            <p className="pt-20 pb-25 lg-pb-20">{beneficios[1]?.value}</p>
                            <ul className="style-none list-item color-rev">
                                <li>{beneficios[2]?.value}</li>
                                <li>{beneficios[3]?.value}</li>
                                <li>{beneficios[4]?.value}</li>
                            </ul>
                            <a href="site-para-seu-negocio.html" className="btn-eight mt-50 lg-mt-30" style={{textDecoration: "none"}}>Leia nosso artigo </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="illustration-holder">
                <img src="/images/assets/ils_16.svg"
                    alt="Desenho de um homem utilizando um quadro para desenvolver algo com uma mulher à sua direita ao lado de um foguete"
                    className="main-illustration ms-auto"/>
                <img src="/images/base/icon/base_icon_transparent_background_13_1.png" alt="Enigma Logo"
                    className="shapes shape-one"/>
                <img src="/images/assets/ils_13_2.svg" className="shapes shape-two" data-aos="fade-down"/>
                <img src="/images/assets/ils_13_2.svg" className="shapes shape-three" data-aos="fade-down"/>
            </div>
            <div className="shapes oval-one"></div>
            <div className="shapes oval-two"></div>
            <div className="shapes oval-three"></div>
        </div>
    )
}

export default Beneficios
