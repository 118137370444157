import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import IFeedback from '../../interfaces/IFeedback';
import Loader from '../Loader/Loader';
import http from '../../http';

interface FeedbackProps {
    feedback : IFeedback[]
}

const Feedback = ({feedback} : FeedbackProps) => {
    const [UrlDaImagem3, setUrlDaImagem3] = useState("")
    const [UrlDaImagem7, setUrlDaImagem7] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [activeIndex, setActiveIndex] = useState(0)

    useEffect(() => {
        AOS.init();

        http.get(`membros/imagem/3`, {
            responseType: 'blob'
        })
        .then(response => {
            setUrlDaImagem3(URL.createObjectURL(response.data))
        })
        http.get(`membros/imagem/7`, {
            responseType: 'blob'
        })
        .then(response => {
            setUrlDaImagem7(URL.createObjectURL(response.data))
            setIsLoading(false)
        })

        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % 2);
          }, 5000);
      
          return () => clearInterval(interval);
    }, []);
    
    //if (isLoading) {
	//	return <Loader />
	//} else {
        return (
            <div className="feedback-section-three position-relative mt-250 lg-mt-130" data-aos="fade-up" style={{ marginBottom: '200px' }}>
                <div className="container">
                    <div className="slider-wrapper">
                    <div className="feedback_slider_three">
                        <div className="fade-in-out" style={{ animation: "fade-in-out 1s ease-in-out forwards", display: activeIndex === 0 ? 'block' : 'none'}}>
                        <div className="feedback-block-three d-md-flex">
                            <div className="img-meta image-link">
                            <a href="https://github.com/MatheusPiske" target="_blank">
                                <img src={UrlDaImagem3} alt="Foto do Matheus CEO da Enigma" style={{ borderRadius: '50px' }} />
                            </a>
                            </div>
                            <div className="text-wrapper">
                            <div className="icon d-flex justify-content-center align-items-center"><img src="/images/icon/icon_15.svg" /></div>
                            <p>{feedback[0]?.value}</p>
                            <div className="name">
                                <h6>{feedback[1]?.value}</h6>
                                <span>CEO da Enigma.</span>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="fade-in-out" style={{ animation: "fade-in-out 1s ease-in-out forwards", display: activeIndex === 1 ? 'block' : 'none' }}>
                        <div className="feedback-block-three d-md-flex">
                            <div className="img-meta image-link">
                            <a href="https://github.com/ArthurRoque" target="_blank">
                                <img src={UrlDaImagem7} alt="Foto do Arthur CEO da Enigma" style={{ borderRadius: '50px' }} />
                            </a>
                            </div>
                            <div className="text-wrapper">
                            <div className="icon d-flex justify-content-center align-items-center"><img src="/images/icon/icon_15.svg" /></div>
                            <p>{feedback[4]?.value}</p>
                            <div className="name">
                                <h6>{feedback[5]?.value}</h6>
                                <span>CEO da Enigma.</span>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <img src="/images/shape/shape_12.svg" className="shapes shape-one" />
            </div>
        )
    //}
}

export default Feedback
