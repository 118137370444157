import axios from "axios";

const http = axios.create({
    baseURL: 'https://enigmaapi-production.up.railway.app/',
    headers: {
        Accept: 'application/json',
        Content: 'application/json'
    }
})

export default http
