import { Alert, Button, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField } from "@mui/material"
import { Box } from "@mui/system"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import SideBarAdmin from "../../../componentes/SideBarAdmin/SideBarAdmin"
import useScript from "../../../hooks/useScript"
import http from "../../../http"

const FormularioMembrosNew = () => {
    useScript("/js/themeSidebar.js");

    const invisible_element = {display: "none"}

    const parametros = useParams()

    const [valueMembros, setValueMembros] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (parametros.id) {
            http.get(`membros/${parametros.id}`)
            .then(response => {
                setValueMembros(response.data.itens[0].value)
                setIsLoading(false)
            })
        }
    }, [parametros])

    const aoSubmeterForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const itemSucesso = document.getElementById("itemSucesso")
        const itemErro = document.getElementById("itemErro")
        itemSucesso && (itemSucesso.style.display = "none")
        itemErro && (itemErro.style.display = "none")

        setIsLoading(true)

        if (parametros.id) {
            http.put(`membros`,
            [{
                id: parametros.id,
                value : valueMembros
            }],
            {headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`
            }}
            )
            .then(() => {
                itemSucesso && (itemSucesso.style.display = "")
                setIsLoading(false)
            })
            .catch(erro => {
                itemErro && (itemErro.style.display = "")
                setIsLoading(false)
            })
        }
    }

    return(
        <>
            <SideBarAdmin />
            
            <section className="home">
                <div className="text">Formulário "Membros"</div>

                <TableContainer id="containerDados" component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <b>Editando item de "Membros"</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <Alert severity="success" id="itemSucesso" style={invisible_element}>Item cadastrado com sucesso!</Alert>
                            <Alert severity="error" id="itemErro" style={invisible_element}>Houve um erro ao atualizar o item!</Alert>

                            {!isLoading && (
                                <TableRow>
                                    <TableCell>
                                        <Box component='form' sx={{width: "100%"}} onSubmit={aoSubmeterForm}>
                                            <TextField
                                                value={valueMembros} 
                                                onChange = {event => setValueMembros(event.target.value)}
                                                label="Nome do item"
                                                variant ="standard"
                                                fullWidth
                                                required
                                            />
                                            <Button sx={{marginTop: 1}} type="submit" fullWidth variant="outlined">Salvar</Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>

                        <TableFooter>
                            {isLoading && (
                                <TableCell colSpan={3}>
                                    <LinearProgress variant="indeterminate" />
                                </TableCell>
                            )}
                        </TableFooter>
                    </Table>
                </TableContainer>
            </section>
        </>
    )
}

export default FormularioMembrosNew
