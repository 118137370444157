import Aos from "aos"
import { useEffect } from "react"
import BotaoVoltarInicio from "../../componentes/BotaoVoltarInicio/BotaoVoltarInicio";
import Rodape from "../../componentes/Rodape/Rodape";

function PesquisasGoogle() {
    useEffect(() => {
        Aos.init();
    }, [])
    
    return(
        <>
        <header className="theme-main-menu sticky-menu theme-menu-four">
			<div className="inner-content">
				<div className="d-flex align-items-center">
					<div className="logo order-lg-0"><a href="/" className="d-block"><img src="images/logo/logo_01.png"
								alt="" width="180" /></a></div>

					<div className="right-widget d-flex align-items-center ms-auto order-lg-3">
						<button className="menu-search-btn tran3s" type="button" data-bs-toggle="offcanvas"
							data-bs-target="#offcanvasTop" aria-controls="offcanvasTop"><i
								className="bi bi-whatsapp"></i></button>
						<a href="/#contato" className="send-msg-btn tran3s d-none d-lg-block" >Entre em contato</a>
					</div>

					<nav className="navbar navbar-expand-lg order-lg-2">
						<button className="navbar-toggler d-block d-lg-none" type="button" data-bs-toggle="collapse"
							data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
							aria-label="Toggle navigation">
							<span></span>
						</button>
						<div className="collapse navbar-collapse" id="navbarNav">
							<ul className="navbar-nav">
								<li className="d-block d-lg-none">
									<div className="logo"><a href="/"><img src="images/logo/logo_01.png" alt=""
												width="130" /></a></div>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="/" role="button" >Home</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="PesquisasGoogle" role="button" >Aparecendo no Google</a>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</div>
		</header>

        <div className="theme-inner-banner">
            <div className="container">
                <h2 className="intro-title text-center">Como aparecer nas pesquisas do Google?</h2>
                <ul className="page-breadcrumb style-none d-flex justify-content-center">
                    <li><a href="/">Home</a></li>
                    <li className="current-page">Como aparecer nas pesquisas do Google?</li>
                </ul>
            </div>
            <img src="images/shape/shape_38.svg" alt="" className="shapes shape-one" />
            <img src="images/shape/shape_39.svg" alt="" className="shapes shape-two" />
        </div>

        <div className="blog-details pt-90 mb-150 lg-pt-40 lg-mb-100">
			<div className="container">
				<div className="row">
					<div className="col-xxl-11 m-auto">
						<div className="row">
							<div className="col-lg-8">
								<article className="blog-meta-three tran3s mt-45">
									<figure className="post-img m0"><img src="images/blog/bussines.png" alt=""
											className="w-100 tran4s" /></figure>
									<div className="post-data">
										<div className="post-tag"><a href="#" >Negócios e Tecnologia</a> - 5 minutos de
											leitura</div>
										<div className="blog-title">
											<h4>Por que meu negócio precisa de um site?</h4>
										</div>
										<p>Ter uma presença digital, por menor que seja, é indispensável para qualquer
											empresa nos tempos
											atuais. Não importa se é uma empresa de grande ou pequeno porte, se é um
											negócio local ou não.
											É preciso ter em mente que uma conta no Instagram ou no Linkedin, por
											exemplo, não são o suficiente para que os mecanismos de busca do google
											possam te indicar para potenciais clientes. As redes sociais também não são
											capazes de passar uma grande autoridade ao cliente, afinal qualquer empresa
											pode criar um perfil de forma rápida, simples e gratuita. <br />
											<br />Resumidamente, um site te permite ter mais <b> alcance</b>,
											mais <b>credibilidade</b> e
											por consequência, aumenta seu número de <b>vendas</b>.
										</p>
										<h5>1. As pesquisas no Google são feitas a partir de soluções para problemas,
											não
											a partir do
											nome
											de sua
											empresa</h5>
										<p> Imagine que você acabou de se mudar ao Rio de Janeiro e é um novo morador.
											Você está precisando encontrar uma nova clínica odontologica para se
											consultar e
											não recebeu indicações. Como faria para encontrar uma boa clínica?
											Você provavelmente faria uma busca no google do tipo "clínica
											odontologica Rio de Janeiro".
											Ao fazer
											isso, o google
											lhe mostraria o site das clínicas mais bem
											indexadas. Essa é a importância de <b>sua empresa estar presente nos
												mecanismos de busca do google</b>, em outras palavras, ter um bom site.
											Assim, as pessoas poderão encontrar seu negócio através de suas necessidades
											e não
											através somente da busca direta pelo nome de sua empresa.</p>
										<h5>2. Credibilidade é essencial</h5>
										<p>As redes sociais são muito importantes para a sua marca, contudo um
											site
											próprio é insubstituível. Isso porque a
											credibilidade que um site próprio passa é algo que nenhuma rede social
											<b> jamais </b> poderá fazer por você. Para comprovar isso basta que você
											mesmo pense como cliente e imagine-se tendo que decidir entre duas empresas
											para contratar. Uma delas possui o famoso "www" em que você pode acessar
											através do navegador e em um ambiente totalmente personalizado, ter acesso a
											mais
											informações sobre a empresa, a
											depoimento de clientes, a formulários de contato e muito mais. <b> Qual você
												se
												sentiria mais seguro em contratar</b>?
										</p>
										<h5>3. Rodar anúncios no Google Ads</h5>
										<p>Qualquer empresa pode anunciar no Google através de vídeos no YouTube,
											destaque nas
											pesquisas e banners em sites ou blogs. Esses anúncios são
											feitos pelo <b>Google Ads</b>, a maior plataforma de anúncios online do
											mundo. Pois bem, é possível além de estar presente nas pesquisas do Google,
											anunciar a sua marca. Atualmente, existem diversos
											gestores e profissionais especialistas em gerar tráfego através do Google
											Ads. Porém, os anúncios na plataforma precisam estar vinculados a um site, e
											é por isso que você precisa de um. Dessa forma, você poderá utilizar a
											plataforma de anúncios do Google a
											favor de seu negócio.
										</p>

										<div className="bottom-widget d-sm-flex align-items-center justify-content-between">
											<ul className="d-flex share-icon align-items-center style-none pt-10">
												<li>Escrito por</li>
												<li>Arthur Roque</li>
											</ul>
										</div>
									</div>
								</article>

							</div>

							<div className="col-lg-4 col-md-6">
								<div className="blog-sidebar ps-xl-5 ps-lg-3 me-xxl-5 mt-45 md-mt-70">
									<div className="sidebar-category mb-50">
										<h5 className="sidebar-title">Outros artigos</h5>
										<ul className="style-none">
											<li className="current-page"><a href="Breve">Como aparecer no google? SEO</a></li>
											<li className="current-page"><a href="Breve">Domínio e hospedagem</a></li>
											<li><a href="Breve">O que é WordPress?</a></li>
											<li><a href="Breve">Como monetizar meu blog?</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

        <Rodape />
        <BotaoVoltarInicio />
        </>
    )
}

export default PesquisasGoogle
