import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import IServicos from '../../interfaces/IServicos';
import http from '../../http';
import Loader from '../Loader/Loader';

interface ServicosProps {
    servicos : IServicos[]
}
const Servicos = ({ servicos }: ServicosProps) => {
    const [UrlDaImagem11, setUrlDaImagem11] = useState("");
    const [UrlDaImagem12, setUrlDaImagem12] = useState("");
    const [UrlDaImagem13, setUrlDaImagem13] = useState("");
    const [UrlDaImagem14, setUrlDaImagem14] = useState("");
    const [isLoading, setIsLoading] = useState(true)
    
    useEffect(() => {
        AOS.init();
        
        http.get(`servicos/imagem/11`, {
            responseType: 'blob'
        })
        .then(response => {
            setUrlDaImagem11(URL.createObjectURL(response.data))
        })
        http.get(`servicos/imagem/12`, {
            responseType: 'blob'
        })
        .then(response => {
            setUrlDaImagem12(URL.createObjectURL(response.data))
        })
        http.get(`servicos/imagem/13`, {
            responseType: 'blob'
        })
        .then(response => {
            setUrlDaImagem13(URL.createObjectURL(response.data))
        })
        http.get(`servicos/imagem/14`, {
            responseType: 'blob'
        })
        .then(response => {
            setUrlDaImagem14(URL.createObjectURL(response.data))
            setIsLoading(false)
        })
      }, []);
    
    //if (isLoading) {
	//    return <Loader />
	//} else {
        return (
            <div className="fancy-feature-thirteen position-relative pb-100 pt-100 lg-pb-100" id="servicos">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-5 col-md-7 m-auto" data-aos="fade-up">
                                <div className="title-style-one text-center mb-55 lg-mb-30">
                                    <div className="sc-title-five">{servicos[0]?.value}</div>
                                    <h2 className="main-title" data-aos="fade-up">{servicos[1]?.value}</h2>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-3 col-sm-6 d-flex aos-init aos-animate" data-aos="fade-up">
                                <div className="block-style-ten tran3s mt-30">
                                    <div className="icon d-flex align-items-end"><img src={UrlDaImagem11}/></div>
                                    <h6><a href="#contato" style={{textDecoration: "none"}}>{servicos[2]?.value}</a></h6>
                                    <p>{servicos[3]?.value}
                                    </p>
                                    <a href="#contato" className="read-btn tran3s"><i className="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                <div className="block-style-ten tran3s mt-30 title-style-three">
                                    <div className="icon d-flex align-items-end"><img src={UrlDaImagem12}/></div>
                                    <h6><a href="#contato" style={{textDecoration: "none"}}>{servicos[4]?.value}</a></h6>
                                    <p>{servicos[5]?.value}</p>
                                    <div className="sc-title">IDEAL PARA BLOGS</div>
                                    <br/>
                                    <a href="#contato" className="read-btn tran3s"><i className="bi bi-arrow-right"></i></a>

                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                <div className="block-style-ten tran3s mt-30">
                                    <div className="icon d-flex align-items-end"><img src={UrlDaImagem13}/></div>
                                    <h6><a href="#contato" style={{textDecoration: "none"}}>{servicos[6]?.value}</a></h6>
                                    <p>{servicos[7]?.value}</p>
                                    <a href="#contato" className="read-btn tran3s"><i className="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6 d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                                <div className="block-style-ten tran3s mt-30 title-style-three">
                                    <div className="icon d-flex align-items-end"><img src={UrlDaImagem14}/></div>
                                    <h6><a href="#contato" style={{textDecoration: "none"}}>{servicos[8]?.value}</a></h6>
                                    <p>{servicos[9]?.value}</p>
                                    <div className="sc-title">IDEAL PARA E-COMMERCE</div>
                                    <br/>
                                    <a href="#contato" className="read-btn tran3s"><i className="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="/images/shape/shape_31.svg" className="shapes shape-one"/>
            </div>
        )
    //}
}

export default Servicos
