import BannerAdmin from "../../../componentes/BannerAdmin/BannerAdmin"
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin/CabecalhoAdmin"

const AdministracaoHome = () => {

    return(
    <>
        <CabecalhoAdmin />
        <BannerAdmin />
    </>
    )

}

export default AdministracaoHome