import Aos from "aos"
import { useEffect } from "react"

const Rodape = () => {

    useEffect(() => {
        Aos.init();
    }, [])

    return (
        <div className="footer-style-four theme-basic-footer pt-100">
            <div className="container">
                    <div className="inner-wrapper">
                        <div className="row">
                            <div className="col-lg-4 footer-intro mb-40">
                                <div className="logo"><a href="/"><img src="/images/logo/logo_01.png" width="130"/></a>
                                </div>
                                <p>Trabalhamos para satisfazer todas as necessidades do seu negócio com as ferramentas mais
                                    inovadoras
                                    e modernas do mercado.</p>
                                <ul className="d-flex social-icon style-none">
                                    <li><a  target="_blank"
                                            href="https://api.whatsapp.com/send?phone=5561981232010&text=Ol%C3%A1%20Arthur!%20Estou%20interessado(a)%20nos%20servi%C3%A7os%20da%20Enigma!"><i
                                                className="bi bi-whatsapp"></i></a></li>
                                    <li><a target="_blank" href="#"><i className="bi bi-instagram"></i></a></li>
                                    <li><a target="_blank" href="https://www.linkedin.com/in/enigma-web-development-164984255/"><i className="bi bi-linkedin"></i></a></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-sm-4 ms-auto mb-30">
                                <h5 className="footer-title">Links</h5>
                                <ul className="footer-nav-link style-none">
                                    <li><a href="/" style={{textDecoration: "none"}}>Home</a></li>
                                    <li><a href="#servicos" style={{textDecoration: "none"}}>Serviços</a></li>
                                    <li><a href="#beneficios" style={{textDecoration: "none"}}>Benefícios</a></li>
                                    <li><a href="#blog" style={{textDecoration: "none"}}>Blog</a></li>
                                    <li><a href="#contato" style={{textDecoration: "none"}}>Contato</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-sm-4 mb-30">
                                <h5 className="footer-title">Serviços</h5>
                                <ul className="footer-nav-link style-none">
                                    <li><a href="#servicos" style={{textDecoration: "none"}}>Um site para o seu negócio</a></li>
                                    <li><a href="#servicos" style={{textDecoration: "none"}}>Soluções com WordPress</a></li>
                                    <li><a href="#servicos" style={{textDecoration: "none"}}>Domínio e Hospedagens</a></li>
                                    <li><a href="#servicos" style={{textDecoration: "none"}}>Soluções Back-end</a></li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-sm-4 mb-30">
                                <h5 className="footer-title">Legal</h5>
                                <ul className="footer-nav-link style-none">
                                    <li><a href="PoliticaDePrivacidade" style={{fontSize: "15px", textDecoration: "none"}}>Política de
                                            privacidade</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="bottom-footer">
                            <div className="d-lg-flex justify-content-between align-items-center">
                                <ul className="order-lg-1 pb-15 d-flex justify-content-center footer-nav style-none">
                                    <li><a href="PoliticaDePrivacidade" style={{textDecoration: "none"}}>Política de privacidade</a></li>
                                    <li><a href="/#contato" style={{textDecoration: "none"}}>Entre em contato</a></li>
                                </ul>
                                <p className="copyright text-center order-lg-0 pb-15">Enigma - Web Development @2022</p>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default Rodape
