import Aos from "aos"
import { useEffect, useState } from "react"
import http from "../../http"
import IBlog from "../../interfaces/IBlog"

interface BlogProps {
    blog : IBlog[]
}

const Blog = ({blog} : BlogProps) => {
    
    useEffect(() => {
        Aos.init();

    }, [])
    
    return(
        <div className="blog-section-three position-relative pt-70 lg-pt-40" id="blog">
            <div className="container">
                    <div className="title-style-three text-center mb-50 lg-mb-20" data-aos="fade-up">
                        <div className="sc-title">{blog[0]?.value}</div>
                        <h2 className="main-title">{blog[1]?.value}<span>{blog[8]?.value}</span></h2>
                    </div>

                    <div className="row gx-xxl-5">
                        <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up">
                            <article className="blog-meta-one color-two tran3s mt-30">
                                <figure className="post-img m0"><a href="SiteParaSeuNegocio" className="w-100 d-block" style={{textDecoration: "none"}}><img
                                            src="/images/blog/blog1_banner.png"
                                            alt="Reunião de negócios com dois computadores em cima de uma mesa bagunçada e um homem apontando para quadro branco"
                                            className="w-100 tran4s"/></a></figure>
                                <div className="post-data">
                                    <div className="post-tag"><a href="SiteParaSeuNegocio" style={{textDecoration: "none"}}>{blog[2]?.value}</a>
                                    </div>
                                    <a href="SiteParaSeuNegocio" className="blog-title" style={{textDecoration: "none"}}>
                                        <h5>{blog[3]?.value}</h5>
                                    </a>
                                </div>
                            </article>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                            <article className="blog-meta-one color-two tran3s mt-30">
                                <figure className="post-img m0"><a href="Breve" className="w-100 d-block" style={{textDecoration: "none"}}><img
                                            src="/images/media/wordpress-banner.png" alt="Logo do Wordpress"
                                            className="w-100 tran4s"/></a>
                                </figure>
                                <div className="post-data">
                                    <div className="post-tag"><a href="Breve" style={{textDecoration: "none"}}>{blog[4]?.value}</a>
                                    </div>
                                    <a href="Breve" className="blog-title" style={{textDecoration: "none"}}>
                                        <h5>{blog[5]?.value}</h5>
                                    </a>
                                </div>
                            </article>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay="200">
                            <article className="blog-meta-one color-two tran3s mt-30">
                                <figure className="post-img m0"><a href="PesquisasGoogle" className="w-100 d-block" style={{textDecoration: "none"}}><img
                                            src="/images/media/seo-banner.png"
                                            alt="Desenho de um computador apresentando um gráfico ascendente"
                                            className="w-100 tran4s"/></a></figure>
                                <div className="post-data">
                                    <div className="post-tag"><a href="PesquisasGoogle" style={{textDecoration: "none"}}>{blog[6]?.value}</a></div>
                                    <a href="PesquisasGoogle" className="blog-title" style={{textDecoration: "none"}}>
                                        <h5>{blog[7]?.value}</h5>
                                    </a>
                                </div>
                            </article>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default Blog
