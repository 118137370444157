import { Button, LinearProgress, TableContainer, TableFooter } from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { useEffect, useState } from "react"
import SideBarAdminSearch from "../../../componentes/SideBarAdmin/SideBarAdminSearch"
import useScript from "../../../hooks/useScript"
import http from "../../../http"
import IBlog from "../../../interfaces/IBlog"
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const AdministracaoBlogNew = () => {
    useScript("/js/themeSidebar.js");

    const [blog, setItensBlog] = useState<IBlog[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [filtro, setFiltro] = useState(sessionStorage.getItem('filtro') || '');

    useEffect(() => {
        http.get('blog')
        .then(response => {
            setItensBlog(response.data.itens)
            setIsLoading(false)
        })
    }, [])

    const atualizaFiltro = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFiltro(event.target.value);
    }

    return (
        <>
            <SideBarAdminSearch atualizaFiltro={atualizaFiltro}/>

            <section className="home">
                <div className="text">Seção "Blog"</div>

                <TableContainer id="containerDados" component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <b>VALOR</b>
                                </TableCell>
                                <TableCell>
                                    <b>NOME</b>
                                </TableCell>
                                <TableCell>
                                    <b>EDITAR</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {blog
                            .filter(blogItem => {
                                const regex = new RegExp(filtro, 'i');
                                return regex.test(blogItem.value);
                            })
                            .map(blogItem => (
                                <TableRow key={blogItem.id}>
                                    <TableCell>{blogItem.value}</TableCell>
                                    <TableCell>{blogItem.name}</TableCell>
                                    <TableCell>
                                        <IconButton href={`/admin/blog/${blogItem.name.includes("imagem") ? "imagem/" : ""}${blogItem.id}`}><EditIcon /></IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                        </TableBody>

                        <TableFooter>
                            {isLoading && (
                                <TableCell colSpan={3}>
                                    <LinearProgress variant="indeterminate" />
                                </TableCell>
                            )}
                        </TableFooter>
                    </Table>
                </TableContainer>
            </section>
        </>
    )
}

export default AdministracaoBlogNew

