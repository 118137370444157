import { useEffect, useState } from "react"
import http from "../../http"
import Loader from "../Loader/Loader"

const BannerAdmin = () => {
	const [valueUsuario, setValueUsuario] = useState('')
	const [isLoading, setIsLoading] = useState(true)

    const body = document.querySelector("body")
	body && (body.style.backgroundColor = "")
	
	useEffect(() => {
		const token = sessionStorage.getItem("token");
        http.get('usuarios', {
			headers: {
			  'Authorization': `Bearer ${token}`
			}
		  })
        .then(response => {
            setValueUsuario(response.data.itens[1].nome)
			setIsLoading(false)
        })
    }, [])

	if (isLoading) {
		return <Loader />
	} else {
		return(
			<div className="hero-banner-one">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-7">
							<a className="slogan" style={{textDecoration : "none"}}>Ferramenta de administrador</a>
							<h1 className="hero-heading">Seja bem vindo, <span style={{color: "var(--prime-three)"}}>{valueUsuario}</span>.</h1>
							<p className="text-lg mb-60 lg-mb-40">Aproveite a ferramenta de editor para personalizar e criar novas experiências para o seu negócio.</p>
							<ul className="style-none button-group d-lg-flex align-items-center">
								<li className="me-4"><a href="admin/cabecalho" className="btn-one ripple-btn" style={{textDecoration : "none"}}>Editar</a></li>
								<li className="help-btn">Precisa de ajuda? <a href="/">Fale conosco</a></li>
							</ul>
						</div>
					</div>
				</div>
				<div className="illustration-holder">
					<img src="images/assets/ils_01.svg" alt="Computador, servidores, banco de dados, nuvem, satélite e roteador" className="main-illustration ms-auto"/>
					<img src="images/assets/bg_01.png" className="shapes bg-shape"/>
					<img src="images/assets/ils_01_1.svg" alt="Celular" className="shapes shape-one"/>
					<img src="images/assets/ils_01_2.svg" className="shapes shape-two"/>
					<img src="images/assets/ils_01_3.svg" className="shapes shape-three"/>
					<img src="images/assets/ils_01_4.svg" className="shapes shape-four"/>
					<img src="images/assets/ils_01_5.svg" className="shapes shape-five"/>
					<img src="images/assets/ils_01_6.svg" className="shapes shape-six"/>
					<img src="images/assets/ils_01_7.svg" className="shapes shape-seven"/>
				</div>
			</div>
		)
	}
}

export default BannerAdmin
