import SettingsIcon from '@mui/icons-material/Settings';
import PsychologyIcon from '@mui/icons-material/Psychology';
import AccessibilityIcon from '@mui/icons-material/Accessibility'; 
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CallIcon from '@mui/icons-material/Call'; 
import NewspaperIcon from '@mui/icons-material/Newspaper'; 
import BarChartIcon from '@mui/icons-material/BarChart'; 
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel'; 
import SearchIcon from '@mui/icons-material/Search'; 
import LogoutIcon from '@mui/icons-material/Logout'; 
import DarkModeIcon from '@mui/icons-material/DarkMode'; 
import LightModeIcon from '@mui/icons-material/LightMode'; 
import { useEffect, useState } from 'react';

const SideBarAdmin = () => {

    return(
        <nav className="sidebar">
            <header>
                    <div className="image-text" style={{justifyContent:"center"}}>
                        <span className="image">
                        <a href="/admin" className="d-block"><img src="/images/logo/creator1.png"
                            alt="Logo da Enigma personalizado escrito 'Enigma Creator'" style={{width:"200px"}}/></a>
                        </span>
                    </div>

                    <i id="sideBarToggleButton" className='bx bx-chevron-right toggle'></i>
            </header>

            <div className="menu-bar">
                    <div className="menu">

                        <li className="search-box">
                            <i><SearchIcon className="bx icon" style={{height: "25px"}} color="action"/></i>
                            <input
                            type="text"
                            id="inputPesquisa"
                            placeholder="Pesquisar..."
                            />
                        </li>

                        <ul className="menu-links" style={{padding:"0"}}>
                            <li className="nav-link" style={{padding:"0"}}>
                                <a href="/admin/cabecalho">
                                    <i><PsychologyIcon className="bx icon" style={{height: "25px"}} color="action"/></i>
                                    <span className="text nav-text">Cabeçalho</span>
                                </a>
                            </li>

                            <li className="nav-link" style={{padding:"0"}}>
                                <a href="/admin/banner">
                                    <i><ViewCarouselIcon className="bx icon" style={{height: "25px"}} color="action"/></i>
                                    <span className="text nav-text">Banner</span>
                                </a>
                            </li>

                            <li className="nav-link" style={{padding:"0"}}>
                                <a href="/admin/servicos">
                                    <i><SettingsIcon className="bx icon" style={{height: "25px"}} color="action"/></i>
                                    <span className="text nav-text">Serviços</span>
                                </a>
                            </li>

                            <li className="nav-link" style={{padding:"0"}}>
                                <a href="/admin/beneficios">
                                    <i><BarChartIcon className="bx icon" style={{height: "25px"}} color="action"/></i>
                                    <span className="text nav-text">Benefícios</span>
                                </a>
                            </li>

                            <li className="nav-link" style={{padding:"0"}}>
                                <a href="/admin/blog">
                                    <i><NewspaperIcon className="bx icon" style={{height: "25px"}} color="action"/></i>
                                    <span className="text nav-text">Blog</span>
                                </a>
                            </li>

                            <li className="nav-link" style={{padding:"0"}}>
                                <a href="/admin/membros">
                                    <i><AccessibilityIcon className="bx icon" style={{height: "25px"}} color="action"/></i>
                                    <span className="text nav-text">Membros</span>
                                </a>
                            </li>

                            <li className="nav-link" style={{padding:"0"}}>
                                <a href="/admin/duvidas">
                                    <i><QuestionMarkIcon className="bx icon" style={{height: "25px"}} color="action"/></i>
                                    <span className="text nav-text">Dúvidas</span>
                                </a>
                            </li>

                            <li className="nav-link" style={{padding:"0"}}>
                                <a href="/admin/contato">
                                    <i><CallIcon className="bx icon" style={{height: "25px"}} color="action"/></i>
                                    <span className="text nav-text">Contato</span>
                                </a>
                            </li>

                        </ul>
                    </div>

                    <div className="bottom-content" style={{cursor: "pointer"}}>
                        <li className="">
                            <a onClick={() => {
                            sessionStorage.removeItem("token");
                            window.location.href = "/login";
                            }}>
                                <i><LogoutIcon className="bx icon" style={{height: "25px"}} color="action"/></i>
                                <span className="text nav-text">Sair</span>
                            </a>
                        </li>

                        <li className="mode">
                            <div className="sun-moon">
                                <i className='bx icon moon'><DarkModeIcon style={{height: "25px", display: "absolute"}} color="action"/></i>
                                <i className='bx icon sun'><LightModeIcon style={{height: "25px", display: "absolute"}}/></i>
                            </div>
                            <span className="mode-text text">Modo escuro</span>

                            <div className="toggle-switch">
                                <span className="switch"></span>
                            </div>
                        </li>
                        
                    </div>
            </div>
        </nav>
    )
}

export default SideBarAdmin
