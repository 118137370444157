import Aos from "aos"
import { useEffect } from "react"

const BotaoVoltarInicio = () => {
    function voltarInicio() {
        $('html, body').animate({scrollTop : 0})
    }

    useEffect(() => {
        Aos.init();
    }, [])

    return (
        <button className="scroll-top" onClick={voltarInicio}>
            <i className="bi bi-arrow-up-short"></i>
        </button>
    )
}

export default BotaoVoltarInicio
