import Cadastro from "./paginas/Administracao/Cadastro/Cadastro";
import Login from "./paginas/Administracao/Login/Login";
import Breve from "./paginas/Blog/Breve";
import PesquisasGoogle from "./paginas/Blog/PesquisasGoogle";
import SiteParaSeuNegocio from "./paginas/Blog/SiteParaSeuNegocio";
import Home from './paginas/Home';
import PoliticaDePrivacidade from "./paginas/PoliticaDePrivacidade/PoliticaDePrivacidade";
import AdministracaoHome from "./paginas/Administracao/Home/AdministracaoHome";
import AdministracaoCabecalhoNew from "./paginas/Administracao/Cabecalho/AdministracaoCabecalho";
import FormularioCabecalhoNew from "./paginas/Administracao/Cabecalho/FormularioCabecalho";
import AdministracaoServicosNew from "./paginas/Administracao/Servicos/AdministracaoServicos";
import FormularioServicosNew from "./paginas/Administracao/Servicos/FormularioServicos";
import AdministracaoBannerNew from "./paginas/Administracao/Banner/AdministracaoBanner";
import FormularioBannerNew from "./paginas/Administracao/Banner/FormularioBanner";
import AdministracaoBeneficiosNew from "./paginas/Administracao/Beneficios/AdministracaoBeneficios";
import FormularioBeneficiosNew from "./paginas/Administracao/Beneficios/FormularioBeneficios";
import AdministracaoBlogNew from "./paginas/Administracao/Blog/AdministracaoBlog";
import FormularioBlogNew from "./paginas/Administracao/Blog/FormularioBlog";
import AdministracaoMembrosNew from "./paginas/Administracao/Membros/AdministracaoMembros";
import FormularioMembrosNew from "./paginas/Administracao/Membros/FormularioMembros";
import AdministracaoContatoNew from "./paginas/Administracao/Contato/AdministracaoContatoNew";
import FormularioContatoNew from "./paginas/Administracao/Contato/FormularioContatoNew";
import AdministracaoDuvidas from "./paginas/Administracao/Duvidas/AdministracaoDuvidas";
import FormularioDuvidas from "./paginas/Administracao/Duvidas/FormularioDuvidas";
import Auth from "./componentes/Auth/Auth";

import { Route, Routes } from "react-router-dom";
import ImagemCabecalho from "./paginas/Administracao/Cabecalho/ImagemCabecalho";
import ImagemBanner from "./paginas/Administracao/Banner/ImagemBanner";
import ImagemServicos from "./paginas/Administracao/Servicos/ImagemServico";
import ImagemBeneficios from "./paginas/Administracao/Beneficios/ImagemBeneficios";
import ImagemBlog from "./paginas/Administracao/Blog/ImagemBlog";
import ImagemMembros from "./paginas/Administracao/Membros/ImagemMembros";
import ImagemDuvidas from "./paginas/Administracao/Duvidas/ImagemDuvidas";
import ImagemContato from "./paginas/Administracao/Contato/ImagemContato";

const Rotas = () => (
  <Routes>
      <Route path="/" element={<Home />}/> 
      <Route path="cadastro" element={<Cadastro />} />
      <Route path="/login" element={<Login />}/> 
      <Route path="SiteParaSeuNegocio" element={<SiteParaSeuNegocio />} /> 
      <Route path="PesquisasGoogle" element={<PesquisasGoogle />}/> 
      <Route path="Breve" element={<Breve />}/> 
      <Route path="PoliticaDePrivacidade" element={<PoliticaDePrivacidade />}/> 

      {/* Rotas protegidas  */}
      <Route path="/admin" element={<Auth><AdministracaoHome /></Auth>}/>
      <Route path="admin/cabecalho" element={<Auth><AdministracaoCabecalhoNew /></Auth>} />
      <Route path="admin/cabecalho/:id" element={<Auth><FormularioCabecalhoNew /></Auth>} />
      <Route path="admin/banner" element={<Auth><AdministracaoBannerNew /></Auth>}/>
      <Route path="admin/banner/:id" element={<Auth><FormularioBannerNew /></Auth>} />
      <Route path="admin/servicos" element={<Auth><AdministracaoServicosNew /></Auth>} />
      <Route path="admin/servicos/:id" element={<Auth><FormularioServicosNew /></Auth>} />
      <Route path="admin/beneficios" element={<Auth><AdministracaoBeneficiosNew /></Auth>} />
      <Route path="admin/beneficios/:id" element={<Auth><FormularioBeneficiosNew /></Auth>} />
      <Route path="admin/blog" element={<Auth><AdministracaoBlogNew /></Auth>} />
      <Route path="admin/blog/:id" element={<Auth><FormularioBlogNew /></Auth>} />
      <Route path="admin/membros" element={<Auth><AdministracaoMembrosNew /></Auth>} />
      <Route path="admin/membros/:id" element={<Auth><FormularioMembrosNew /></Auth>} />
      <Route path="admin/duvidas" element={<Auth><AdministracaoDuvidas /></Auth>} />
      <Route path="admin/duvidas/:id" element={<Auth><FormularioDuvidas /></Auth>} />
      <Route path="admin/contato" element={<Auth><AdministracaoContatoNew /></Auth>} />
      <Route path="admin/contato/:id" element={<Auth><FormularioContatoNew /></Auth>} />

      <Route path="admin/cabecalho/imagem/:id" element={<Auth><ImagemCabecalho/></Auth>}/>
      <Route path="admin/banner/imagem/:id" element={<Auth><ImagemBanner/></Auth>}/>
      <Route path="admin/servicos/imagem/:id" element={<Auth><ImagemServicos/></Auth>}/>
      <Route path="admin/beneficios/imagem/:id" element={<Auth><ImagemBeneficios/></Auth>}/>
      <Route path="admin/blog/imagem/:id" element={<Auth><ImagemBlog/></Auth>}/>
      <Route path="admin/membros/imagem/:id" element={<Auth><ImagemMembros/></Auth>}/>
      <Route path="admin/duvidas/imagem/:id" element={<Auth><ImagemDuvidas/></Auth>}/>
      <Route path="admin/contato/imagem/:id" element={<Auth><ImagemContato/></Auth>}/>
  </Routes>
);

export default Rotas;
