import Aos from "aos"
import { useEffect, useState } from "react"
import http from "../../http"
import IContato from "../../interfaces/IContato"

interface ContatoProps {
    contato : IContato[]
}

const Contato = ({contato} : ContatoProps) => {
    
    useEffect(() => {
        Aos.init();

    }, [])

    useEffect(() => {
        if($("#contact-form").length) {
            //$('#contact-form').validator();
            // when the form is submitted
            $('#contact-form').on('submit', function (e) {

                // if the validator does not prevent form submit
                if (!e.isDefaultPrevented()) {
                    var url = "inc/c.php";

                    // POST values in the background the the script URL
                    $.ajax({
                        type: "POST",
                        url: url,
                        data: $(this).serialize(),
                        success: function (data)
                        {
                            // data = JSON object that contact.php returns

                            // we recieve the type of the message: success x danger and apply it to the
                            var messageAlert = 'alert-' + data.type;
                            var messageText = data.message;

                            // let's compose Bootstrap alert box HTML
                            var alertBox = '<div class="alert ' + messageAlert + ' alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>' + messageText + '</div>';

                            // If we have messageAlert and messageText
                            if (messageAlert && messageText) {
                                // inject the alert to .messages div in our form
                                $('#contact-form').find('.messages').html(alertBox);
                                // empty the form
                                //$('#contact-form')[0].reset();
                            }
                        }
                    });
                    return false;
                }
            });
          }
      }, []);
    
    return(
        <div className="contact-section-one mb-170 lg-mb-120" id="contato">
            <div className="mt-100 lg-mt-70">
                    <div className="container">
                        <div className="row gx-xxl-5" style={{justifyContent: "center"}}>
                            <div className="col-lg-6 d-flex order-lg-last">
                                <div className="form-style-one">
                                    <h3 className="form-title pb-40 lg-pb-20">{contato[0]?.value}</h3>
                                    <form action="inc/c.php" id="contact-form" data-toggle="validator">
                                        <div className="messages"></div>
                                        <div className="row controls">
                                            <div className="col-12">
                                                <div className="input-group-meta form-group mb-30">
                                                    <label>Nome*</label>
                                                    <input type="text" placeholder="Digite o seu nome aqui" name="name"
                                                        required data-error="Nome é obrigatório."/>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="input-group-meta form-group mb-30">
                                                    <label>Email*</label>
                                                    <input type="email" placeholder="seuemail@email.com" name="email"
                                                        required data-error="É necessário um email válido."/>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="input-group-meta form-group mb-30">
                                                    <textarea placeholder="Sua mensagem*" name="message" required
                                                        data-error="Por favor, nos deixe uma mensagem."></textarea>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div className="col-12" style={{display: "flex", justifyContent: "center"}}><button
                                                    className="btn-eight ripple-btn">{contato[1]?.value}</button></div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default Contato
