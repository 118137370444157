import Aos from "aos";
import { useEffect, useState } from "react"
import http from "../../http";
import ICabecalho from "../../interfaces/ICabecalho";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Loader from "../Loader/Loader";

interface CabecalhoProps {
    cabecalho : ICabecalho[]
}
const Cabecalho = ({ cabecalho }: CabecalhoProps) => {
    const [UrlDaImagem6, setUrlDaImagem6] = useState("");
    const [isLoading, setIsLoading] = useState(true)
    
    useEffect(() => {
        Aos.init();

        http.get(`cabecalho/imagem/6`, {
            responseType: 'blob'
        })
        .then(response => {
            setUrlDaImagem6(URL.createObjectURL(response.data))
            setIsLoading(false)

            document.querySelectorAll('a').forEach(link => {
                link.addEventListener('click', () => {
                    const navbarCollapse = document.getElementById('navbarNav')
                    if (navbarCollapse) {
                        navbarCollapse.classList.remove('show');
                    }
                    const navbarToggler = document.querySelector('#navbar-toggler')
                    if (navbarToggler) {
                        navbarToggler.setAttribute('aria-expanded', 'false')
                    }
                });
            });
        })

    }, [])

    if (isLoading) {
		return <Loader />
	} else {
        return(
            <header className="theme-main-menu sticky-menu theme-menu-four">
                <div className="inner-content">
                        <div className="d-flex align-items-center">
                            <div className="logo order-lg-0"><a href="/" className="d-block"><img src={UrlDaImagem6}
                                        alt="Logo da Enigma escrito 'Enigma - Web Development'" width="180" id="imagemCabecalho"/></a></div>

                            <div className="right-widget d-flex align-items-center ms-auto order-lg-3">
                                <a href="login"><button className="menu-search-btn tran3s" type="button" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasTop" aria-controls="offcanvasTop"><a
                                        href="login"
                                        className="bi"
                                        style={{color: "currentcolor"}}><LockOpenIcon style={{marginBottom:"5px"}} /></a></button></a>
                                <a href="https://api.whatsapp.com/send?phone=5561981232010&text=Ol%C3%A1%20Arthur!%20Estou%20interessado(a)%20nos%20servi%C3%A7os%20da%20Enigma!"><button className="menu-search-btn tran3s" type="button" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasTop" aria-controls="offcanvasTop"><a
                                        href="https://api.whatsapp.com/send?phone=5561981232010&text=Ol%C3%A1%20Arthur!%20Estou%20interessado(a)%20nos%20servi%C3%A7os%20da%20Enigma!"
                                        className="bi bi-whatsapp"
                                        style={{color: "currentcolor"}}></a></button></a>
                                <a href="#contato" className="send-msg-btn tran3s d-none d-lg-block" style={{textDecoration: "none", marginRight: "20px"}} id="botaoContato">Entre em contato</a>
                            </div>

                            <nav className="navbar navbar-expand-lg order-lg-2" id="navbarHome">
                                <button id="navbar-toggler" className="navbar-toggler d-block d-lg-none" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    style={{boxShadow: "none"}}>
                                    <span></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <ul className="navbar-nav">
                                        <li className="d-block d-lg-none">
                                            <div className="logo"><a href="/"><img src="/images/logo/logo_01.png"
                                                        width="130"/></a></div>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#" role="button">{cabecalho[0]?.value}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#servicos" role="button">{cabecalho[1]?.value}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#beneficios" role="button">{cabecalho[2]?.value}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#blog" role="button">{cabecalho[3]?.value}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#contato" role="button">{cabecalho[4]?.value}</a>
                                        </li>
                                    </ul>
                                    <div className="mobile-content d-block d-lg-none">
                                        <div className="d-flex flex-column align-items-center justify-content-center mt-70">
                                            <a href="#contato" className="send-msg-btn tran3s mb-10">Entre em contato</a>
                                            <div className="call-button">Arthur Roque <a
                                                    href="https://api.whatsapp.com/send?phone=5561981232010&text=Ol%C3%A1%20Arthur!%20Estou%20interessado(a)%20nos%20servi%C3%A7os%20da%20Enigma!">(61)
                                                    98123-2010</a></div>
                                            <div className="call-button">Matheus Piske <a
                                                    href="https://api.whatsapp.com/send?phone=5547988042584&text=Ol%C3%A1%20Matheus!%20Estou%20interessado(a)%20nos%20servi%C3%A7os%20da%20Enigma!">(47)
                                                    98804-2584</a> </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                </div>
            </header>
        )
    }
}

export default Cabecalho 
