import { Alert, CircularProgress } from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import { useEffect, useState } from 'react';
import http from '../../../http';

function Cadastro() {
    const invisible_element = {display: "none"}
    
    const [valueEmail, setValueEmail] = useState('')
    const [valueSenha, setValueSenha] = useState('')
    const [valueSenhaConfirm, setValueSenhaConfirm] = useState('')
    const [valueNomeUsuario, setValueNomeUsuario] = useState('')
    
    useEffect(() => {
        AOS.init();
      }, []);

      function toggleSenhaVisivel() {
        const campoSenha = document.getElementById("password") as HTMLInputElement
        const imagemVisao = document.getElementById("passVisonToggleOne") as HTMLInputElement
        
        if (campoSenha && imagemVisao) {
          if (campoSenha.type === "password") {
            campoSenha.type = "text";
            imagemVisao.classList.toggle("eye-slash")
          } else {
            campoSenha.type = "password";
            imagemVisao.classList.toggle("eye-slash")
          }
        }
      }

      function toggleSenhaConfirmacaoVisivel() {
        const campoSenha = document.getElementById("confirmPassword") as HTMLInputElement
        const imagemVisao = document.getElementById("passVisonToggleTwo") as HTMLInputElement
        
        if (campoSenha && imagemVisao) {
          if (campoSenha.type === "password") {
            campoSenha.type = "text";
            imagemVisao.classList.toggle("eye-slash")
          } else {
            campoSenha.type = "password";
            imagemVisao.classList.toggle("eye-slash")
          }
        }
      }

      const aoSubmeterLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const inputSenha = document.getElementById("password") as HTMLInputElement | null
        const inputConfirmarSenha = document.getElementById("confirmPassword") as HTMLInputElement | null
        if (inputSenha && inputConfirmarSenha) {
            if (inputSenha.value != inputConfirmarSenha.value) {
                alert("Senhas informadas não são iguais!")
            } else {
                const carregandoComponent = document.getElementById("carregandoComponent")
                if (carregandoComponent) {
                    carregandoComponent.style.display = "flex"
                }

                http.post(`cadastro`, [
                    {
                        nome : valueNomeUsuario,
                        email : valueEmail,
                        senha : valueSenha
                    }
                ])
                .then(response => {
                    setValueNomeUsuario("")
                    setValueEmail("")
                    setValueSenha("")
                    setValueSenhaConfirm("")
                    const elementoSucesso = document.getElementById("mensagemSucesso")
                    const elementoFalha = document.getElementById("mensagemErro")

                    if (elementoFalha) {
                        elementoFalha.style.display = "none";
                    }
                    if (elementoSucesso) {
                        elementoSucesso.style.display = "";
                    }
                    if (carregandoComponent) {
                        carregandoComponent.style.display = "none"
                    }
                })
                .catch(erro => {
                    setValueNomeUsuario("")
                    setValueEmail("")
                    setValueSenha("")
                    setValueSenhaConfirm("")
                    const elementoFalha = document.getElementById("mensagemErro")
                    const elementoSucesso = document.getElementById("mensagemSucesso")

                    if (elementoSucesso) {
                        elementoSucesso.style.display = "none";
                    }
                    if (elementoFalha) {
                        elementoFalha.style.display = "";
                    }
                    if (carregandoComponent) {
                        carregandoComponent.style.display = "none"
                    }
                })
            }
        }
    }

    return (
    <>
        <div id="carregandoComponent" style={{display : "none", position: "absolute", top : "0", left : "0", width: "100%", height: "100%", zIndex: "2", backgroundColor: "rgba(0, 0, 0, 0.5)", alignItems: "center", justifyContent : "center"}}> <CircularProgress style={{width : "70px", height : "70px"}}/></div>
        <div className="main-page-wrapper">
            <div className="user-data-page clearfix d-md-flex">
                <div className="illustration-wrapper d-none d-md-flex align-items-center">
                    <h3>Gostaria de alavancar <br/> os seus negócios? Consulte <a href="/">nosso site</a></h3>
                    <div className="illustration-holder">
                        <img src="images/assets/ils_22.svg" alt="Animação de um homem segurando uma lanterna" className="illustration m-auto"/>
                    </div>
                </div>

                <div className="form-wrapper">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="logo"><a href="/" className="d-block"><img src="images/logo/logo_01.png" alt="Logo da Enigma" width="131"/></a></div>
                        <a href="/" className="go-back-button tran3s" style={{textDecoration: "none"}}>Voltar</a>
                    </div>
                    <form action="#" className="user-data-form mt-60 lg-mt-40" onSubmit={aoSubmeterLogin}>
                        <h2>Olá, seja bem vindo <br/> de volta!</h2>
                        <Alert severity="error" id="mensagemErro" style={invisible_element}>Não é possível realizar o cadastro!</Alert>
                        <Alert severity="success" id="mensagemSucesso" style={invisible_element}>Cadastro realizado com sucesso!</Alert>
                        <p className="header-info pt-20 pb-20 lg-pt-10 lg-pb-10">Já possui uma conta? Faça login <a href="/login">aqui</a></p>

                        <div className="row">
                            <div className="col-12">
                                <div className="input-group-meta mb-20">
                                    <label>Nome de usuário*</label>
                                    <input type="text" placeholder="Digite o nome do usuário" pattern="^[a-zA-Z0-9_-]{3,16}$" title="O nome de usuário deve ter entre 3 e 16 caracteres e pode incluir letras maiúsculas e minúsculas, números, hífens e sublinhados." onChange={event => setValueNomeUsuario(event.target.value)} value={valueNomeUsuario} required/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group-meta mb-20">
                                    <label>Email*</label>
                                    <input type="email" placeholder="Digite seu e-mail" pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$" title="Por favor, insira um endereço de e-mail válido." onChange={event => setValueEmail(event.target.value)} value={valueEmail} required/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group-meta mb-20">
                                    <label>Senha*</label>
                                    <input id="password" type="password" placeholder="Digite sua senha" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$" title="A senha deve ter pelo menos 8 caracteres e incluir pelo menos uma letra minúscula, uma letra maiúscula e um número." onChange={event => setValueSenha(event.target.value)} value={valueSenha} required/>
                                    <span className="placeholder_icon" onClick={toggleSenhaVisivel}><span className="passVicon" id="passVisonToggleOne"><img src="images/icon/icon_40.svg" alt="Olho que indica se a senha é visível. Inicia como não visível" /></span></span>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="input-group-meta mb-20">
                                    <label>Confirme sua senha*</label>
                                    <input id="confirmPassword" type="password" placeholder="Digite sua senha novamente" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$" title="A senha deve ter pelo menos 8 caracteres e incluir pelo menos uma letra minúscula, uma letra maiúscula e um número." style={{marginBottom:"none"}} onChange={event => setValueSenhaConfirm(event.target.value)} value={valueSenhaConfirm} required/>
                                    <span className="placeholder_icon" onClick={toggleSenhaConfirmacaoVisivel}><span className="passVicon" id="passVisonToggleTwo"><img src="images/icon/icon_40.svg" alt="Olho que indica se a senha é visível. Inicia como não visível" /></span></span>
                                </div>
                            </div>
                            <div className="col-12">
                                <button type="submit" className="btn-eight w-100 mt-50 mb-40 lg-mt-30 lg-mb-30">Se inscrever</button>
                            </div>
                            <div className="col-12">
                                <p className="text-center copyright-text m0">Copyright @2022 Enigma - Web Development</p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
    )
}

export default Cadastro
