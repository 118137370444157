import LogoutIcon from '@mui/icons-material/Logout'; 

const CabecalhoAdmin = () => { 

    return(
        <>
            <header className="theme-main-menu sticky-menu theme-menu-four" style={{position:"absolute"}}>
                <div className="inner-content">
                        <div className="d-flex align-items-center">
                            <div className="logo order-lg-0"><a href="/admin" className="d-block"><img src="/images/logo/creator1.png"
                                        alt="Logo da Enigma personalizado escrito 'Enigma Creator'" width="180"/></a></div>

                            <div className="right-widget d-flex align-items-center ms-auto order-lg-3">
                                <div>
                                    <a onClick={() => {
                                    sessionStorage.removeItem("token");
                                    window.location.href = "/login";
                                    }}><button className="menu-search-btn tran3s" type="button" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasTop" aria-controls="offcanvasTop" style={{marginRight: "0px"}} ><a
                                    style={{color : "currentcolor"}}
                                    onClick={() => {
                                    sessionStorage.removeItem("token");
                                    window.location.href = "/login";
                                    }}
                                    className="bi"><LogoutIcon style={{marginBottom:"5px"}} /></a></button></a>
                                </div>
                                
                                <a href="https://api.whatsapp.com/send?phone=5561981232010&text=Ol%C3%A1%20Arthur!%20Estou%20interessado(a)%20nos%20servi%C3%A7os%20da%20Enigma!"><button className="menu-search-btn tran3s estiloBototesCabecalhoAdmin" type="button" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasTop" aria-controls="offcanvasTop"><a
                                        style={{color : "currentcolor"}}
                                        href="https://api.whatsapp.com/send?phone=5561981232010&text=Ol%C3%A1%20Arthur!%20Estou%20interessado(a)%20nos%20servi%C3%A7os%20da%20Enigma!"
                                        className="bi bi-whatsapp"></a></button></a>
                            </div>

                            <nav className="navbar navbar-expand-lg order-lg-2">
                                <button className="navbar-toggler d-block d-lg-none" type="button" data-bs-toggle="collapse"
                                    style={{boxShadow: "none"}}
                                    data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                    <span></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <ul className="navbar-nav">
                                        <li className="d-block d-lg-none">
                                            <div className="logo"><a href=""><img src="/images/logo/creator1.png"
                                                        width="130"/></a></div>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/admin/cabecalho" role="button">Cabeçalho</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/admin/banner" role="button">Banner</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/admin/servicos" role="button">Serviços</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/admin/beneficios" role="button">Benefícios</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/admin/blog" role="button">Blog</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/admin/membros" role="button">Membros</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/admin/duvidas" role="button">Dúvidas</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/admin/contato" role="button">Contato</a>
                                        </li>
                                    </ul>

                                    <div className="mobile-content d-block d-lg-none">

                                        <div className="d-flex flex-column align-items-center justify-content-center mt-70">
                                            <a href="#" className="send-msg-btn tran3s mb-10" style={{textDecoration: "none"}}>Entre em contato</a>
                                            <div className="call-button">Arthur Roque <a
                                                    href="https://api.whatsapp.com/send?phone=5561981232010&text=Ol%C3%A1%20Arthur!%20Estou%20interessado(a)%20nos%20servi%C3%A7os%20da%20Enigma!">(61)
                                                    98123-2010</a></div>
                                            <div className="call-button">Matheus Piske <a
                                                    href="https://api.whatsapp.com/send?phone=5547988042584&text=Ol%C3%A1%20Matheus!%20Estou%20interessado(a)%20nos%20servi%C3%A7os%20da%20Enigma!">(47)
                                                    98804-2584</a> </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                </div>
            </header>
        </>
    )
}

export default CabecalhoAdmin
