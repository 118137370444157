import Aos from "aos"
import { useEffect } from "react"
import BotaoVoltarInicio from "../../componentes/BotaoVoltarInicio/BotaoVoltarInicio";
import Rodape from "../../componentes/Rodape/Rodape";

function PoliticaDePrivacidade() {
    useEffect(() => {
        Aos.init();
    }, [])

    return(
        <>
        <header className="theme-main-menu sticky-menu theme-menu-four">
			<div className="inner-content">
				<div className="d-flex align-items-center">
					<div className="logo order-lg-0"><a href="/" className="d-block"><img src="images/logo/logo_01.png"
								alt="Logo da Enigma escrito 'Enigma - Web Development'" width="180" /></a></div>

					<div className="right-widget d-flex align-items-center ms-auto order-lg-3">
						<button className="menu-search-btn tran3s" type="button" data-bs-toggle="offcanvas"
							data-bs-target="#offcanvasTop" aria-controls="offcanvasTop"><a
								style={{color: "currentColor"}}
								href="https://api.whatsapp.com/send?phone=5561981232010&text=Ol%C3%A1%20Arthur!%20Estou%20interessado%20nos%20servi%C3%A7os%20da%20Enigma!"
								className="bi bi-whatsapp"></a></button>
						<a href="#contato" className="send-msg-btn tran3s d-none d-lg-block" style={{textDecoration: "none"}}>Entre em contato</a>
					</div>

					<nav className="navbar navbar-expand-lg order-lg-2">
						<button className="navbar-toggler d-block d-lg-none" type="button" data-bs-toggle="collapse"
							data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
							aria-label="Toggle navigation">
							<span></span>
						</button>
						<div className="collapse navbar-collapse" id="navbarNav">
							<ul className="navbar-nav">
								<li className="d-block d-lg-none">
									<div className="logo"><a href="/"><img src="images/logo/logo_01.png" alt=""
												width="130" /></a></div>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="/" role="button">Home</a>
								</li>
							</ul>
							<div className="mobile-content d-block d-lg-none">
								<div className="d-flex flex-column align-items-center justify-content-center mt-70">
									<a href="#contato" className="send-msg-btn tran3s mb-10" style={{textDecoration: "none"}}>Entre em contato</a>
									<div className="call-button">Arthur Roque <a
											href="https://api.whatsapp.com/send?phone=5561981232010&text=Ol%C3%A1%20Arthur!%20Estou%20interessado%20nos%20servi%C3%A7os%20da%20Enigma!">(61)
											98123-2010</a></div>
									<div className="call-button">Matheus Piske <a
											href="https://api.whatsapp.com/send?phone=5547988042584&text=Ol%C3%A1%20Matheus!%20Estou%20interessado%20nos%20servi%C3%A7os%20da%20Enigma!">(47)
											98804-2584</a> </div>
								</div>
							</div>
						</div>
					</nav>
				</div>
			</div>
		</header>

        <div className="theme-inner-banner">
			<div className="container">
				<h2 className="intro-title text-center">Nossa política de privacidade</h2>
				<ul className="page-breadcrumb style-none d-flex justify-content-center">
					<li><a href="">Home</a></li>
					<li className="current-page">Política de Privacidade</li>
				</ul>
			</div>
			<img src="images/shape/shape_38.svg" alt="" className="shapes shape-one" />
			<img src="images/shape/shape_39.svg" alt="" className="shapes shape-two" />
		</div>

        <div className="faq-section-one lg-mt-80 lg-mb-80" style={{marginTop: "100px", marginBottom: "100px"}}>
			<div className="container">
				<div className="row">
					<p>A Enigma preza em manter a privacidade de suas informações, provendo este
						compromisso com a segurança necessária para resguardar e sustentar a integridade das
						informações disponíveis em sua Intranet, Internet ou Extranet. Solicitamos informações pessoais
						apenas quando realmente precisamos delas para lhe fornecer um
						serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também
						informamos por que estamos coletando e como será usado.</p>
					<p>Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado.
						Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis ​​para evitar
						perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados. Não
						compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto
						quando exigido por lei.
					</p>
					<p>O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de
						que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar
						responsabilidade por suas respectivas políticas de privacidade.</p>
					<p>Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não
						possamos fornecer alguns dos serviços desejados. O uso continuado de nosso site será considerado
						como aceitação de nossas práticas em torno de
						privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do
						usuário e informações pessoais, entre em contato conosco.</p>
					<p>Podemos usar cookies e outras tecnologias para melhorar a experiência do usuário, mensurar o desempenho
					   e personalizar o conteúdo de marketing. Podemos reconhecer um visitante constante e melhorar a experiência
					   de compra. Os cookies são pequenos arquivos de dados transferidos de um site da web para o disco do seu computador.
					</p>
				</div>
			</div>
		</div>

        <Rodape />
        <BotaoVoltarInicio />
        </>
    )
}

export default PoliticaDePrivacidade
