import Aos from "aos";
import { useEffect, useState } from "react"
import http from "../../http";
import IBanner from "../../interfaces/IBanner";
import Loader from "../Loader/Loader";

interface BannerProps {
    banner : IBanner[]
    isLoadingBanner : boolean
}

const Banner = ({ banner, isLoadingBanner }: BannerProps) => {
    const [UrlDaImagem3, setUrlDaImagem6] = useState("");
    const [isLoading, setIsLoading] = useState(true)
    
    useEffect(() => {
        Aos.init();

        http.get(`home/imagem/3`, {
            responseType: 'blob'
        })
        .then(response => {
            setUrlDaImagem6(URL.createObjectURL(response.data))
            setIsLoading(false)
        })
    }, [])
    
    while (isLoading && isLoadingBanner) {
		return <Loader />
	} //else {
        return (
            <div className="hero-banner-five">
                <div className="container container-banner">
                        <div className="row">
                            <div className="col-xxl-6 col-md-7">
                                <h1 className="hero-heading">{banner[0]?.value}<span>{banner[4]?.value}</span></h1>
                                <p className="text-lg mb-50 pt-40 pe-xl-5 md-pt-30 md-mb-40">{banner[1]?.value}</p>
                            </div>
                        </div>
                </div>
                <div className="illustration-holder">
                        <img src="/images/assets/ils_13.svg" alt="Estrutura de construção" className="main-illustration ms-auto"/>
                        <img src={UrlDaImagem3}
                            alt="Enigma Logo girando em cima da estrutura de construção" className="shapes shape-one"/>
                        <img src="/images/assets/ils_13_2.svg" className="shapes shape-two" data-aos="fade-down"/>
                        <img src="/images/assets/ils_13_2.svg" className="shapes shape-three" data-aos="fade-down"/>
                </div>
                <div className="shapes oval-one"></div>
            </div>
        )
    //}
}

export default Banner
