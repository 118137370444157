import Aos from "aos";
import { useEffect } from "react"

function Breve() {
    useEffect(() => {
        Aos.init();
    }, [])

    return (
        <>
        <header className="theme-main-menu sticky-menu theme-menu-four">
			<div className="inner-content">
				<div className="d-flex align-items-center">
					<div className="logo order-lg-0"><a href="" className="d-block"><img src="images/logo/logo_01.png"
								alt="Logo da Enigma escrito 'Enigma - Web Development'" width="180" /></a></div>

					<div className="right-widget d-flex align-items-center ms-auto order-lg-3">
						<button className="menu-search-btn tran3s" type="button" data-bs-toggle="offcanvas"
							data-bs-target="#offcanvasTop" aria-controls="offcanvasTop"><a
								style={{color: "currentColor"}}
								href="https://api.whatsapp.com/send?phone=5561981232010&text=Ol%C3%A1%20Arthur!%20Estou%20interessado%20nos%20servi%C3%A7os%20da%20Enigma!"
								className="bi bi-whatsapp"></a></button>
						<a href="#contato" className="send-msg-btn tran3s d-none d-lg-block" style={{textDecoration: "none"}}>Entre em contato</a>
					</div>

					<nav className="navbar navbar-expand-lg order-lg-2">
						<button className="navbar-toggler d-block d-lg-none" type="button" data-bs-toggle="collapse"
							data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
							aria-label="Toggle navigation">
							<span></span>
						</button>
						<div className="collapse navbar-collapse" id="navbarNav">
							<ul className="navbar-nav">
								<li className="d-block d-lg-none">
									<div className="logo"><a href=""><img src="images/logo/logo_01.png" alt=""
												width="130" /></a></div>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="" role="button">Home</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#servicos" role="button">Serviços</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#beneficios" role="button">Benefícios</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#blog" role="button">Blog</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#contato" role="button">Contato</a>
								</li>
							</ul>
							
							<div className="mobile-content d-block d-lg-none">
								<div className="d-flex flex-column align-items-center justify-content-center mt-70">
									<a href="contact-us.html" className="send-msg-btn tran3s mb-10">Send us Message</a>
									<div className="call-button">Call us <a href="#">(+880) 321 782 110</a></div>
								</div>
							</div>
						</div>
					</nav>
				</div>
			</div>
		</header>

        <div className="error-page-content d-flex align-items-center justify-content-center">
			<div className="container">
				<div className="row">
					<div className="col-xxl-6 col-lg-7 m-auto">
						<h2 className="pb-3">Esse artigo ainda está sendo elaborado... </h2>
						<p className="me-xxl-5 ms-xxl-5">Agradecemos o interesse! :)</p>
						<a href="" className="btn-eight" style={{textDecoration: "none"}}>Voltar para a home</a>
					</div>
				</div>
			</div>
			<img src="images/shape/shape_49.svg" alt="" className="shapes shape-one w-100" />
		</div>

        <button className="scroll-top">
			<i className="bi bi-arrow-up-short"></i>
		</button>
        </>
    )
}

export default Breve
