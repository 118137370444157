import { Alert, Button, Input, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SideBarAdmin from "../../../componentes/SideBarAdmin/SideBarAdmin";
import useScript from "../../../hooks/useScript";
import http from "../../../http";

const ImagemDuvidas = (): JSX.Element => {
    const invisible_element_margin = { display: "none", marginTop: "20px" };
    const invisible_element_margin_text = { display: "none", marginTop: "20px", fontSize: "15px", fontWeight: "bolder" };
    const invisible_element = { display: "none" };

    useScript("/js/themeSidebar.js");

    const [valueImagemDuvidas, setValueImagemDuvidas] = useState<File | string>("");
    const [UrlDaImagem, setUrlDaImagem] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [file, setFile] = useState<string | null>(null);
    const parametros = useParams<{ id?: string }>();

    useEffect(() => {
        if (parametros.id) {
            http.get(`duvidas/${parametros.id}`).then((response) => {
                setIsLoading(false);
            });

            http
                .get(`duvidas/imagem/${parametros.id}`, {
                    responseType: "blob",
                })
                .then((response) => {
                    setUrlDaImagem(URL.createObjectURL(response.data));
                });
        }
    }, [parametros]);

    const capturarImagem = (response: React.ChangeEvent<HTMLInputElement>): void => {
        const imagem = response.target.files![0];
        setValueImagemDuvidas(imagem);
        setFile(URL.createObjectURL(imagem));

        const imagemSelecionada = document.getElementById("imagemSelecionada");
        imagemSelecionada && (imagemSelecionada.style.display = "");
        const textoDeImagemSelecionada = document.getElementById("textoDeImagemSelecionada");
        textoDeImagemSelecionada && (textoDeImagemSelecionada.style.display = "");
    };

    const aoSubmeterForm = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        const token = sessionStorage.getItem("token");
        const itemSucesso = document.getElementById("itemSucesso");
        const itemErro = document.getElementById("itemErro");
        itemSucesso && (itemSucesso.style.display = "none");
        itemErro && (itemErro.style.display = "none");

        setIsLoading(true);

        const formData = new FormData();
        formData.append("file", valueImagemDuvidas as File);

        if (parametros.id) {
            http
                .put(`duvidas/imagem/${parametros.id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    itemSucesso && (itemSucesso.style.display = "");
                    setIsLoading(false);
                });
        }
    };

    return(
        <>
            <SideBarAdmin />
            
            <section className="home">
                <div className="text">Formulário "Dúvidas"</div>

                <TableContainer id="containerDados" component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <b>Editando imagem de "dúvidas"</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <Alert severity="success" id="itemSucesso" style={invisible_element}>Imagem atualizada com sucesso!</Alert>
                            <Alert severity="error" id="itemErro" style={invisible_element}>Houve um erro ao atualizar a imagem!</Alert>

                             {!isLoading && (
                                <TableRow>
                                    <TableCell>
                                        <Box component='form' sx={{width: "100%"}} onSubmit={aoSubmeterForm}>
                                            <Input
                                                id="my-file-input"
                                                type="file"
                                                onChange={capturarImagem}
                                            />
                                            <Button sx={{marginTop: 1}} type="submit" fullWidth variant="outlined">Salvar</Button>
                                            <h2 style={{marginTop: "20px", fontSize: "15px", fontWeight: "bolder"}}>Imagem atual que está sendo utilizada:</h2>
                                            <img src={UrlDaImagem!} alt="Imagem atual que está sendo utilizada" />
                                            <h2 style={invisible_element_margin_text} id="textoDeImagemSelecionada">Imagem que será inclusa:</h2>
                                            <img src={file!} style={invisible_element_margin} id="imagemSelecionada" alt="Imagem selecionada" />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>

                        <TableFooter>
                            {isLoading && (
                                <TableCell colSpan={3}>
                                    <LinearProgress variant="indeterminate" />
                                </TableCell>
                            )}
                        </TableFooter>
                    </Table>
                </TableContainer>
            </section>
        </>
    )
}

export default ImagemDuvidas
