import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Navigate } from "react-router";
import http from "../../http";
import Loader from "../Loader/Loader";
import LoaderDark from "../Loader/LoaderDark";

const Auth = ({ children }) => {
  const [usuarioLogado, setUsuarioLogado] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      setIsLoading(false);
      return;
    }

    http.get(``, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
      setUsuarioLogado(true);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    if (sessionStorage.getItem('temaEscuro') && location.pathname != '/admin') {
      return <LoaderDark />
    } else {
      return <Loader />
    }
  } else if (!usuarioLogado) {
    return <Navigate to="/login" />;
  } else {
    return children;
  }
};

export default Auth;

