import Servicos from "../../componentes/Servicos/Servicos";
import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'aos/dist/aos.js';
import Feedback from "../../componentes/Feedback/Feedback";
import useScript from "../../hooks/useScript";
import Loader from "../../componentes/Loader/Loader";
import Cabecalho from "../../componentes/Cabecalho/Cabecalho";
import Banner from "../../componentes/Banner/Banner";
import Beneficios from "../../componentes/Beneficios/Beneficios";
import Blog from "../../componentes/Blog";
import Contato from "../../componentes/Contato/Contato";
import Duvidas from "../../componentes/Duvidas/Duvidas";
import Rodape from "../../componentes/Rodape/Rodape";
import BotaoVoltarInicio from "../../componentes/BotaoVoltarInicio/BotaoVoltarInicio";
import http from "../../http";
import ICabecalho from "../../interfaces/ICabecalho";
import IBanner from "../../interfaces/IBanner";
import IServicos from "../../interfaces/IServicos";
import IBeneficios from "../../interfaces/IBeneficios";
import IFeedback from "../../interfaces/IFeedback";
import IBlog from "../../interfaces/IBlog";
import IDuvidas from "../../interfaces/IDuvidas";
import IContato from "../../interfaces/IContato";

function App() {
    
    const [cabecalho, setItemCabecalho] = useState<ICabecalho[]>([]);
    const [banner, setItemBanner] = useState<IBanner[]>([])
    const [servicos, setItemServicos] = useState<IServicos[]>([])
    const [beneficios, setItemBeneficios] = useState<IBeneficios[]>([])
    const [blog, seiItemBlog] = useState<IBlog[]>([])
    const [feedback, setItemFeedback] = useState<IFeedback[]>([])
    const [duvidas, setItemDuvidas] = useState<IDuvidas[]>([])
    const [contato, setItemContato] = useState<IContato[]>([])
    const [isLoadingCabecalho, setIsLoadingCabecalho] = useState(true)
    const [isLoadingBanner, setIsLoadingBanner] = useState(true)
    const [isLoadingServicos, setIsLoadingServicos] = useState(true)
    const [isLoadingBeneficios, setIsLoadingBeneficios] = useState(true)
    const [isLoadingBlog, setIsLoadingBlog] = useState(true)
    const [isLoadingFeedback, setIsLoadingFeedback] = useState(true)
    const [isLoadingDuvidas, setIsLoadingDuvidas] = useState(true)
    const [isLoadingContato, setIsLoadingContato] = useState(true)

    useScript("/js/theme.js");
    useScript("/js/navbarToggle.js");
    useScript("/vendor/slick/slick.min.js");
    useScript("/vendor/jquery.min.js");
    useScript("/vendor/aos-next/dist/aos.js");
    useScript("/vendor/jquery.counterup.min.js");
    useScript("/vendor/jquery.waypoints.min.js");
    useScript("/vendor/fancybox/dist/jquery.fancybox.min.js");
    useScript("/vendor/validator.js");

    useEffect(() => {
        AOS.init();
        
        // Pegando os itens da api e passando como PROPS
        http.get(`cabecalho`)
        .then(response => {
            setItemCabecalho(response.data.itens)
            setIsLoadingCabecalho(false)
        })
        
        http.get(`home`)
        .then(response => {
            setItemBanner(response.data.itens)
            setIsLoadingBanner(false)
        })
        
        http.get(`servicos`)
        .then(response => {
            setItemServicos(response.data.itens)
            setIsLoadingServicos(false)
        })
        
        http.get(`beneficios`)
        .then(response => {
            setItemBeneficios(response.data.itens)
            setIsLoadingBeneficios(false)
        })
        
        http.get(`blog`)
        .then(response => {
            seiItemBlog(response.data.itens)
            setIsLoadingBlog(false)
        })
        
        http.get(`membros`)
        .then(response => {
            setItemFeedback(response.data.itens)
            setIsLoadingFeedback(false)
        })

        http.get(`duvidas`)
        .then(response => {
            setItemDuvidas(response.data.itens)
            setIsLoadingDuvidas(false)
        })
        
        http.get(`contato`)
        .then(response => {
            setItemContato(response.data.itens)
            setIsLoadingContato(false)
        })
        
    }, []);
    
    if (isLoadingCabecalho && isLoadingBanner && isLoadingServicos && isLoadingBeneficios && isLoadingBlog && isLoadingFeedback && isLoadingDuvidas && isLoadingContato) {
        return <Loader />
	} else {
        return (
            <div className="main-page-wrapper">  
                <Cabecalho cabecalho={cabecalho}/>
                <Banner banner={banner} isLoadingBanner={isLoadingBanner}/>
                <Servicos servicos={servicos}/>
                <Beneficios beneficios={beneficios}/>
                <Blog blog={blog}/>   
                <Feedback feedback={feedback}/>
                <Contato contato={contato}/>
                <Duvidas duvidas={duvidas}/>
                <Rodape />
                <BotaoVoltarInicio />
            </div>
        )
    }
}

export default App;
