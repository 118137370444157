import Aos from "aos"
import { useEffect, useState } from "react"
import IDuvidas from "../../interfaces/IDuvidas"

interface DuvidasProps {
    duvidas : IDuvidas[]
}

const Duvidas = ({duvidas} : DuvidasProps) => {
    
    useEffect(() => {
        Aos.init();

    }, [])

    return (
        <div className="fancy-feature-seven lg-mt-50 sm-mt-20" id="duvidas">
            <div className="container">
                    <div className="row">
                        <div className="col-xxl-4 col-lg-5">
                            <div className="block-style-five md-pb-50" data-aos="fade-right">
                                <div className="title-style-one">
                                    <h2 className="main-title">{duvidas[0]?.value}</h2>
                                </div>
                                <p className="pt-10 pb-15">{duvidas[1]?.value}</p>
                                <a href="" className="btn-five ripple-btn" style={{textDecoration: "none"}}>Voltar</a>
                            </div>
                        </div>

                        <div className="col-lg-7 col-lg-6 ms-auto" data-aos="fade-left">
                            <div className="accordion accordion-style-one" id="accordionOne">
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                                            style={{backgroundColor: "white", boxShadow: "none"}}>
                                            {duvidas[2]?.value}
                                        </button>
                                    </div>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                                        data-bs-parent="#accordionOne">
                                        <div className="accordion-body">
                                            <p>{duvidas[3]?.value}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"
                                            style={{backgroundColor: "white", boxShadow: "none"}}>
                                            {duvidas[4]?.value}
                                        </button>
                                    </div>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionOne">
                                        <div className="accordion-body">
                                            <p>{duvidas[5]?.value}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree" style={{backgroundColor: "white", boxShadow: "none"}}>
                                            {duvidas[6]?.value}
                                        </button>
                                    </div>
                                    <div id="collapseThree" className="accordion-collapse collapse"
                                        aria-labelledby="headingThree" data-bs-parent="#accordionOne">
                                        <div className="accordion-body">
                                            <p>{duvidas[7]?.value}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour" aria-expanded="false"
                                            aria-controls="collapseFour" style={{backgroundColor: "white", boxShadow: "none"}}>
                                            {duvidas[8]?.value}
                                        </button>
                                    </div>
                                    <div id="collapseFour" className="accordion-collapse collapse"
                                        aria-labelledby="headingFour" data-bs-parent="#accordionOne">
                                        <div className="accordion-body">
                                            <p>{duvidas[9]?.value}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <img src="/images/shape/shape_13.svg" className="shapes shape-one"/>
            <img src="/images/shape/shape_14.svg" className="shapes shape-two"/>
            <img src="/images/shape/shape_15.svg" className="shapes shape-three"/>
        </div>
    )
}

export default Duvidas
